"use client"; // Error components must be a Client Component
import {
  type AppRouterErrorProps,
  appRouterSsrErrorHandler,
} from "@highlight-run/next/ssr";
import Link from "next/link";
import { ButtonPrimary } from "~/modules/common/components/atoms/buttons/ButtonPrimary";
import { ErrorPage } from "~/modules/common/components/pages/ErrorPage";
import { globalConfig } from "~/static/config";

export default appRouterSsrErrorHandler(({ error }: AppRouterErrorProps) => {
  console.error(error);

  return (
    <ErrorPage>
      <Link href={globalConfig.websiteUrl}>
        <ButtonPrimary>{"Retour à l'accueil"}</ButtonPrimary>
      </Link>
    </ErrorPage>
  );
});
